// ////////////////////////////スマホ/タブレット判別
var _ua = (function (u) {
  return {
    Tablet: (u.indexOf('windows') != -1 && u.indexOf('touch') != -1)
      || u.indexOf('ipad') != -1
      || (u.indexOf('android') != -1 && u.indexOf('mobile') == -1)
      || (u.indexOf('firefox') != -1 && u.indexOf('tablet') != -1)
      || u.indexOf('kindle') != -1
      || u.indexOf('silk') != -1
      || u.indexOf('playbook') != -1,
    Mobile: (u.indexOf('windows') != -1 && u.indexOf('phone') != -1)
      || u.indexOf('iphone') != -1
      || u.indexOf('ipod') != -1
      || (u.indexOf('android') != -1 && u.indexOf('mobile') != -1)
      || (u.indexOf('firefox') != -1 && u.indexOf('mobile') != -1)
      || u.indexOf('blackberry') != -1
  };
})(window.navigator.userAgent.toLowerCase());
// if(_ua.Mobile){}

var osVer;
osVer = 'Android';

var ua = navigator.userAgent.toLowerCase();
var iPadFlag = false;
if (/android|ipod|ipad|iphone|macintosh/.test(ua) && 'ontouchend' in document) {
  iPadFlag = true;
}
// if (navigator.userAgent.indexOf(osVer)>0){
// }

var breakNum = 768;
var tabletNum = 1024;
// ////////////////////////////////////init
$(function () {
  setScroll();
  if ($('#wrapper').hasClass('home')) {
  }
  // ///////////
  if (_ua.Tablet || _ua.Mobile) {} else {}
// ///////////
});

$(window).on('load', function () {
  // heightLineGroup()
  svg4everybody();
  judgeWinSize();
  setMainMenu();
  customSelect();
  setShareFloating();
  // setDemo()
  // setTink()
  // setMainMenu()
  // setModalMenu()
  setAcc();
  setLoaded();
  setWay();
  setDom();
  setActive();
  setScrollTop();
  // var ua = window.navigator.userAgent.toLowerCase()
  // if (ua.indexOf('windows nt') !== -1) {
  // } else {
  //   setLuxy()
  // }
  // setScrollTop()
  // setTextile()
  if ($('#wrapper').hasClass('home')) {
    // setMainVis()
  }
  if (!_ua.Mobile && !_ua.Tablet) {
    // setPal()
  }
});

// 江戸まちごよみ　ランダム画像
function setRandomImg () {
  $('#js-random .eyecatch').each(function () {
    var keyNum = Math.floor(Math.random() * ((12 + 1) - 1)) + 1 - 1;
    var target = $(this).find('span').eq(keyNum);
    target.prependTo($(this));
  });
}

// trLink
function setTrLink () {
  $('tr[data-href]').click(function (e) {
    window.location = $(this).data('href');
  });
}

// ///////TOPボタン
function setScrollTop () {
  var baseHeight = 10;
  var footH = $('footer').height();
  var timer = false;
  fixedToTop();

  $(window).scroll(function () {
    fixedToTop();
  });

  function fixedToTop () {
    var scrollHeight = $(document).height() - footH;
    if ($(this).scrollTop() <= baseHeight) {
      $('#toTop').removeClass('active');
    }else if ($(this).scrollTop() > baseHeight) {
      $('#toTop').addClass('active');
    }
    if ($(window).height() + $(this).scrollTop() >= scrollHeight) {
      $('#toTop').addClass('footPos');
    }else {
      $('#toTop').removeClass('footPos');
    }
  }
}

// /////郵便番号検索
function setPostal () {
  $('#zip_01').jpostal({
    postcode: [
      '#zip_01'
    ],
    address: {
      '#pref_01': '%3',
      '#address_01': '%4%5'
    }
  });
}

// scrollHint
function setScrollHint () {
  new ScrollHint('.js-scrollable', {
    i18n: {
      scrollable: ''
    }
  });
// setTimeout(function () {
//   $('.scroll-hint-icon-wrap').addClass('is-active')
// }, 2000)
}

// シェアボタン
function setShareFloating () {
  $(window).bind('scroll', function () {
    scrollHeight = $(document).height();
    scrollPosition = $(window).height() + $(window).scrollTop();
    if ((scrollHeight - scrollPosition) / scrollHeight <= 0.05) {
      $('.mod-mail-magazine').addClass('def');
      $('.share_module-all').addClass('def');
    } else {
      $('.mod-mail-magazine').removeClass('def');
      $('.share_module-all').removeClass('def');
    }
  });
}

// fv active操作
function setActive () {
  // $('.archive-eat .content-search .scaleWay').addClass('active')
}

// DOM操作
function setDom () {
  $('.nav-global a>span>span').each(function () {
    var elem = '<span class="clone">' + $(this).text() + '</span>';
    $(this).parent().append(elem);
  });

  $('.effect_01 span').each(function () {
    var text = $.trim(this.textContent),
      html = '';

    text.split('').forEach(function (v) {
      html += '<span>' + v + '</span>';
    });

    this.innerHTML = html;
  });
}

// ////////////////////////////スライダー
function setCarousel01 () {
  var target = '.carousel-style_01';
  $(target).each(function () {
    var slider = $(this).find('.screen');
    var nav = $(this).find('.thumbs');
    var slideCount = $(this).find('.screen>.item').length;
    var navCount = $(this).find('.thumbs>.item').length;
    var navTarget = '#' + slider.attr('id');
    var slideTarget = '#' + nav.attr('id');

    if (slideCount > 1) {
      slider.slick({
        dots: false,
        infinite: true,
        autoplay: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        // fade: true,
        // cssEase: 'linear',
        pauseOnFocus: false,
        pauseOnHover: false,
        pauseOnDotsHover: false,
        asNavFor: slideTarget,
        waitForAnimate: false
      });
    }

    if (navCount > 1) {
      var centerModeSw = true;
      if (navCount == 2) {
        centerModeSw = false;
      }
      nav.slick({
        dots: false,
        infinite: true,
        autoplay: false,
        speed: 500,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        // slidesToShow: 2,
        // slidesToScroll: 3,
        // fade: true,
        // cssEase: 'linear',
        pauseOnFocus: false,
        pauseOnHover: false,
        pauseOnDotsHover: false,
        asNavFor: navTarget,
        centerMode: centerModeSw,
        focusOnSelect: true,
        waitForAnimate: false
      // focusOnSelect: true
      });
    }

  // slider.on('touchmove', function (event, slick, currentSlide, nextSlide) {
  //   slider.slick('slickPlay')
  // })
  // nav.on('touchmove', function (event, slick, currentSlide, nextSlide) {
  //   slider.slick('slickPlay')
  // })
  //
  // var timer = false
  // var currentWidth = window.innerWidth
  // window.addEventListener('resize', function () {
  //   if (currentWidth == window.innerWidth) {
  //     return
  //   }
  //   currentWidth = window.innerWidth
  //   if (timer !== false) {
  //     clearTimeout(timer)
  //   }
  //   timer = setTimeout(function () {
  //     slider.slick('slickPlay')
  //     nav.slick('slickPlay')
  //   }, 200)
  // })
  });
}

function setCarousel02 () {
  var target = '.carousel-style_02';
  $(target).each(function () {
    var slider = $(this).find('.screen');
    var slideCount = $(this).find('.screen>.item').length;

    if (slideCount > 1) {
      slider.slick({
        dots: false,
        infinite: true,
        autoplay: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        cssEase: 'linear',
        pauseOnFocus: false,
        pauseOnHover: false,
        pauseOnDotsHover: false,
        waitForAnimate: false
      });
    }
  });
  $('.slick-prev,.slick-next').click(function (e) {
    e.preventDefault();
  });
}

function setCarousel03 () {
  var slider = '.carousel-style_03';

  const swiper = new Swiper(slider, {
    // wrapperClass: 'screen',
    // slideClass: 'item',
    //
    loop: true,
    speed: 700,
    // effect: 'fade',
    navigation: {
      nextEl: '.carousel-next',
      prevEl: '.carousel-prev'
    },
    // thumbs: {
    //   swiper: thumbs
    // },
    pagination: {
      el: '.swiper-pagination',
      type: 'progressbar'
    },
  // autoplay: {
  //   delay: 2000,
  //   disableOnInteraction: false
  // },
  // scrollbar: {
  //   el: '.swiper-scrollbar'
  // }
  });
}

function setCarousel04 () {
  var slider = '.carousel-style_04';

  const swiper = new Swiper(slider, {
    loop: true,
    speed: 700,
    effect: 'fade',
    autoplay: {
      delay: 8000,
      disableOnInteraction: false
    },
    navigation: {
      nextEl: '.carousel-next',
      prevEl: '.carousel-prev'
    }
  });
}

function setCarousel05 () {
  var slider = '.carousel-style_05';

  const swiper = new Swiper(slider, {
    slidesPerView: 2.2,
    slidesPerGroup: 2,
    // loop: true,
    speed: 700,
    spaceBetween: 10,
    // effect: 'fade',
    // autoplay: {
    //   delay: 2000,
    //   disableOnInteraction: false
    // },
    navigation: {
      nextEl: '.carousel-next',
      prevEl: '.carousel-prev'
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'progressbar'
    },
    breakpoints: {
      769: {
        slidesPerView: 6,
        slidesPerGroup: 6
      }
    }
  });
}

// コンテンツ検索
function contentSearch () {
  $('.js-search').on('click', function () {
    var target = '.js-panel-' + $(this).attr('id').replace('js-search-', '');
    if (!$(this).hasClass('active')) {
      $('.js-search').removeClass('active');
      $('.js-search-panel').hide();
      $(this).find('input').focus();
      $(this).addClass('active');
      $(target).slideDown('fast');
    }else {
      $(this).find('input').blur();
      $(this).removeClass('active');
      $(target).fadeOut('fast');
    }
  });
  $('.btn-close').on('click', function () {
    $(this).parents('.content-search-panel').fadeOut('fast');
    $(this).parents('.content-search').find('.js-search').removeClass('active');
    $(this).parents('.content-search').find('.js-search').blur();
  });

  $('.js-clearBtn').on('click', function () {
    $(this).parents('.content-search-panel').find('input').prop('checked', false);
  });
}

// //////////////////////////メインメニュー
function setMainMenu () {
  var current_scrollY;
  $('#menu-main,#menu-search').on('click', function () {
    var target = $(this).attr('id');
    if (!$('#menu-main').hasClass('active')) {
      openFnc(target);
    }else {
      closeFnc();
    }
  });
  $('#mainNav a').on('click', function (event) {
    event.stopPropagation();
  });

  // $('#clickblocker').on('click', function () {
  //   closeFnc()
  // })

  var winW = $(window).width();
  var timer = false;
  var currentWidth = window.innerWidth;
  window.addEventListener('resize', function () {
    winW = $(window).width();
    if (currentWidth == window.innerWidth) {
      return;
    }
    currentWidth = window.innerWidth;
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      if (winW > tabletNum) {
        closeFnc();
      }
    }, 200);
  });

  function openFnc (target) {
    current_scrollY = $(window).scrollTop();
    // $('body').addClass('breakH')
    $('#menu-main').addClass('active');
    $('#wrapper').addClass('menuOpen');
    if (target == 'menu-main') {
      $('#wrapper').addClass('flag-menu');
    }else {
      $('#wrapper').addClass('flag-search');
    }
    $('#wrap-search .js-way').addClass('active');
    $('body, html').animate({ scrollTop: 0 }, 0);
    // $('#outerMenu').css('top', -(current_scrollY))
    menuOpenFlag = true;
  }

  function closeFnc () {
    // $('body').removeClass('breakH')
    // $('#wrapper').addClass('breakLuxy')
    $('#menu-main').removeClass('active');
    $('#wrapper').removeClass('menuOpen');
    $('#wrapper').removeClass('flag-menu');
    $('#wrapper').removeClass('flag-search');
    $('#wrap-search .js-way').removeClass('active');
    menuOpenFlag = false;
    $('html, body').prop({
      scrollTop: current_scrollY
    });
  }
}

// selectにデザイン適用
function customSelect () {
  $('.custom-select').each(function () {
    var classes = $(this).attr('class'),
      id = $(this).attr('id'),
      name = $(this).attr('name');
    var template = '<div class="' + classes + '">';
    template += '<span class="custom-select-trigger">' + $(this).attr('placeholder') + '</span>';
    template += '<div class="custom-options">';
    $(this).find('option').each(function () {
      if ($(this).attr('selected')) {
        template += '<span class="selection custom-option" data-value="' + $(this).attr('value') + '">' + $(this).html() + '</span>';
      }else {
        template += '<span class="custom-option" data-value="' + $(this).attr('value') + '">' + $(this).html() + '</span>';
      }
    });
    template += '</div></div>';

    $(this).wrap('<div class="custom-select-wrapper"></div>');
    $(this).hide();
    $(this).after(template);
  });
  $('.custom-option:first-of-type').hover(function () {
    $(this).parents('.custom-options').addClass('option-hover');
  }, function () {
    $(this).parents('.custom-options').removeClass('option-hover');
  });
  $('.custom-select-trigger').on('click', function () {
    $('html').one('click', function () {
      $('.custom-select').removeClass('opened');
    });
    $(this).parents('.custom-select').toggleClass('opened');
    event.stopPropagation();
  });
  $('.custom-option').on('click', function () {
    $(this).parents('.custom-select-wrapper').find('select').val($(this).data('value'));
    $(this).parents('.custom-options').find('.custom-option').removeClass('selection');
    $(this).addClass('selection');
    $(this).parents('.custom-select').removeClass('opened');
    window.location.href = $(this).data('value');
  // $(this).parents('.custom-select').find('.custom-select-trigger').text($(this).text())
  });
}

// ////////////////////////////アコーディオン
function setAcc () {
  $('.article-style_19 .sec-ttl').on('click', function () {
    $(this).parents('.article-style_19').toggleClass('active');
    $(this).next().slideToggle('fast');
  });
  $('.faq-wrap .sec-question').on('click', function () {
    $(this).toggleClass('active');
    $(this).next().slideToggle('fast');
  });
}

// ////////////ウィンドウサイズを判別
function judgeWinSize () {
  var winW = $(window).width();
  if (winW > breakNum) {
    $('#wrapper').addClass('setPc');
  } else {
    $('#wrapper').addClass('setSp');
  }

  var timer = false;
  var currentWidth = window.innerWidth;
  window.addEventListener('resize', function () {
    if (currentWidth == window.innerWidth) {
      return;
    }
    currentWidth = window.innerWidth;
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      winW = $(window).width();
      if (winW > breakNum) {
        $('#wrapper').addClass('setPc');
        $('#wrapper').removeClass('setSp');
      } else {
        $('#wrapper').addClass('setSp');
        $('#wrapper').removeClass('setPc');
      }
    }, 200);
  });
}

// //////////////////////////////ロード完了
function setLoaded () {
  $('#loading').addClass('loaded');
  loadStart();
}

function loadStart () {
  $('#wrapper').addClass('loaded');
  // setGnav()
  timer = setTimeout(function () {
    $('#wrapper').addClass('loadedDone');
  }, 400);
  timer = setTimeout(function () {
    $('#wrapper').addClass('loadEnd');
    $('#loading').addClass('loadEnd');
  }, 1000);
// $('body,html').animate({
//   scrollTop: 0
// }, 0, 'swing')
}

// function setGnav () {
//   var i = 0
//   setInterval(function () {
//     $('#headNav li').eq(i).addClass('start')
//     i++
//     if (i >= $('#headNav li').length) i = 0
//   }, 100)
// }

// ///////waypoint
function setWay () {
  $('.way,.alphaWay,.scaleWay,.setAnimation,#bgTx>div,.nullWay,.js-way').waypoint(function (direction) {
    var activePoint = $(this.element);
    if (direction === 'down') { // scroll down
      activePoint.addClass('active');
      timer = setTimeout(function () {
        activePoint.addClass('activeAfter');
      }, 1000);
    }
  //    else{ //scroll up
  //        activePoint.removeClass('active')
  //    }
  }, {
    offset: '70%'
  });

  $('.js-scrollable').waypoint(function (direction) {
    var activePoint = $(this.element);
    if (direction === 'down') { // scroll down
      activePoint.addClass('active');
      timer = setTimeout(function () {
        activePoint.removeClass('active');
      }, 4000);
    }
  //    else{ //scroll up
  //        activePoint.removeClass('active')
  //    }
  }, {
    offset: '70%'
  });

  $('.shuffle').waypoint(function (direction) {
    var activePoint = $(this.element);
    if (direction === 'down') { // scroll down
      activePoint.addClass('active');
      // var container = $(this.element)
      activePoint.shuffleLetters();
    }
  //    else{ //scroll up
  //        activePoint.removeClass('active')
  //    }
  }, {
    offset: '70%'
  });

  $('.ttlStyle_05,.fade_01,.fade_02').waypoint(function (direction) {
    var activePoint = $(this.element);
    if (direction === 'down') { // scroll down
      activePoint.addClass('active');
    }
  //    else{ //scroll up
  //        activePoint.removeClass('active')
  //    }
  }, {
    offset: '70%'
  });
}

// /////////////////////////////スムーススクロール
function setScroll () {
  // var headerHight = $('header').height() + 40
  var headerHight = 0;
  $('a[href^="#"]').click(function (e) {
    var href = $(this).attr('href');
    var target = $(href == '#' || href == '' ? 'html' : href);
    var position = target.offset().top - headerHight;

    if ($(this).hasClass('unqNav')) {
      closeFnc();
    }
    $.when(
      $('html, body').animate({
        scrollTop: position
      }, 400, 'swing'),
      e.preventDefault()
    ).done(function () {
      var diff = target.offset().top - headerHight;
      if (diff === position) {
      } else {
        $('html, body').animate({
          scrollTop: diff
        }, 10, 'swing');
      }
    });
  });
}

function startScroll () {
  var headerHight = 0;
  // var headerHight = $('header').height() + 40
  var href = $(location).attr('hash');
  if (href) {
    timer = setTimeout(function () {
      var target = $(href == '#' || href == '' ? 'html' : href);
      var position = target.offset().top - headerHight;
      $.when(
        $('html, body').animate({
          scrollTop: position
        }, 400, 'swing')
      ).done(function () {
        var diff = target.offset().top - headerHight;
        if (diff === position) {
        } else {
          $('html, body').animate({
            scrollTop: diff
          }, 10, 'swing');
        }
      });
    }, 1100);
  }
}

// ////////////////////////////////////ランドスケープ判定
var isLandscape = function () {
  if (window.innerHeight > window.innerWidth) {
    jQuery('body').addClass('portrait');
    jQuery('body').removeClass('landscape');
  } else {
    jQuery('body').addClass('landscape');
    jQuery('body').removeClass('portrait');
  }
};

if (_ua.Mobile) {
  jQuery(window).resize(function () {
    isLandscape();
  });
  isLandscape();
}

// //////////////////////////////高さ揃え
function heightLineGroup () {
  // setAutoHeight('#photoDiary .articleStyle_02 .inner')
  var winW = $(window).width();
  if (winW > breakNum) {
    // setAutoHeight('.setH_01 h2', 4)
    // setAutoHeight('.setH_01 h2')
    // setAutoHeight('#photoDiary .articleStyle_02 .inner')
    // setAutoHeight(".setH_01 .summary",3)
  } else {
    setAutoHeight('.setH_01 h2', 2);
    setAutoHeight('.setH_02 h2', 2);
  // setAutoHeight("#setH_08 h2",2)
  }

  startResize();
}

function setAutoHeight (target, count) {
  $(target).tile(count);
}

function startResize () {
  var timer = false;
  var currentWidth = window.innerWidth;
  window.addEventListener('resize', function () {
    if (currentWidth == window.innerWidth) {
      return;
    }
    currentWidth = window.innerWidth;
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      heightLineGroup();
    }, 200);
  });
}
